<template>
  <div class="reg-user-manage">
    <div class="search-area">
      <el-form size="small" :inline="true" :model="searchParams" class="">
        <el-form-item label="关键字">
          <el-input
            v-model="searchParams.searchContent"
            placeholder="请输入昵称/手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册日期">
          <el-date-picker
            @change="handleDateParamsChange"
            v-model="selectDate"
            type="daterange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            v-show="authorities.includes('BACK:FRONT_T_USER_PAGE_LIST')"
            type="primary"
            @click="handleSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="opt-area">
      <el-button
        v-show="authorities.includes('BACK:FAST_SAVE_CLIENT_USER')"
        size="small"
        @click="handleCreateUser"
        type="primary"
        icon="el-icon-plus"
        >新增</el-button
      >
      <el-button
        v-show="authorities.includes('BACK:FAST_UPDATE_CLIENT_USER')"
        :disabled="selectUserIds.length != 1"
        size="small"
        @click="handleEditUserInfo('batch')"
        type="primary"
        icon="el-icon-edit"
        >编辑</el-button
      >
      <el-button
        v-show="authorities.includes('BACK:BACK_T_USER_ENABLE_UPDATE')"
        :disabled="selectUserIds.length == 0"
        size="small"
        @click="handleEnbleOrDisableUser('batch', 1)"
        type="primary"
        icon="el-icon-video-play"
        >启用</el-button
      >
      <el-button
        v-show="authorities.includes('BACK:BACK_T_USER_ENABLE_UPDATE')"
        :disabled="selectUserIds.length == 0"
        size="small"
        @click="handleEnbleOrDisableUser('batch', 0)"
        type="primary"
        icon="el-icon-video-pause"
        >禁用</el-button
      >
      <el-button
        :disabled="selectUserIds.length == 0"
        size="small"
        @click="handleDeleteUser('batch')"
        type="primary"
        icon="el-icon-delete"
        >删除</el-button
      >
      <el-button
        v-show="authorities.includes('BACK:BACK_T_USER_PASS_INIT')"
        :disabled="selectUserIds.length == 0"
        size="small"
        @click="handleResetPassword('batch')"
        type="primary"
        icon="el-icon-key"
        >重置密码</el-button
      >
    </div>
    <div class="list-area">
      <el-table
        v-show="authorities.includes('BACK:FRONT_T_USER_PAGE_LIST')"
        :data="userList"
        v-loading="loading"
        row-key="id"
        height="100%"
        style="width: 100%; min-width: 1400px"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#eef1f6' }"
      >
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" label="注册方式">
          <template slot-scope="scope">
            <span>{{
              scope.row.regSource == 1 ? "内部添加" : "外部注册"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTimeString"
          align="center"
          label="注册时间"
        >
        </el-table-column>
        <el-table-column prop="id" align="center" label="id"> </el-table-column>
        <el-table-column prop="nickname" align="center" label="昵称">
        </el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="手机号"
        ></el-table-column>
        <el-table-column align="center" label="会议次数">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleSeeMeetingDetail(scope.row)"
              >{{ scope.row.meetingNum }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="rechargeRmbAllNum"
          align="center"
          label="充值总金额"
        >
        </el-table-column>
        <el-table-column align="center" label="设备">
          <template slot-scope="scope">
            <span>{{
              scope.row.deviceNum ? scope.row.deviceNum : "暂无"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="金币数量">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleSeeRechargeRecord(scope.row)"
              >{{ scope.row.rechargeCoinAllNum }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="coinBalanceAllNum"
          align="center"
          label="金币余额"
        >
        </el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="充值"
          width="140px"
        >
          <template slot-scope="scope">
            <el-link
              v-show="authorities.includes('BACK:RECHARGE_MONEY_TO_USER')"
              type="primary"
              :underline="false"
              @click="handleRecharge(scope.row)"
              >去充值</el-link
            >
            <el-link
              v-show="authorities.includes('BACK:DEDUCT_MONEY_TO_USER')"
              type="primary"
              class="m-l-10"
              :underline="false"
              @click="handleDeduct(scope.row)"
              >去扣除</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="enable" align="center" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.state == 1 ? "正常" : "禁用" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="操作"
          width="100px"
        >
          <template slot-scope="scope">
            <el-link
              v-show="authorities.includes('BACK:FAST_UPDATE_CLIENT_USER')"
              type="primary"
              :underline="false"
              @click="handleEditUserInfo('row', scope.row)"
              >编辑</el-link
            >
            <el-link
              v-show="authorities.includes('BACK:BACK_T_USER_PASS_INIT')"
              type="primary"
              class="m-l-10"
              :underline="false"
              @click="handleResetPassword('row', scope.row)"
              >重置密码</el-link
            >
            <el-link
              type="primary"
              class="m-l-10"
              v-show="
                scope.row.state == 1 &&
                authorities.includes('BACK:BACK_T_USER_ENABLE_UPDATE')
              "
              :underline="false"
              @click="handleEnbleOrDisableUser('row', 0, scope.row)"
              >禁用</el-link
            >
            <el-link
              type="primary"
              class="m-l-10"
              v-show="
                scope.row.state == 0 &&
                authorities.includes('BACK:BACK_T_USER_ENABLE_UPDATE')
              "
              :underline="false"
              @click="handleEnbleOrDisableUser('row', 1, scope.row)"
              >启用</el-link
            >
            <el-link
              type="primary"
              class="m-l-10"
              :underline="false"
              @click="handleDeleteUser('row', scope.row)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-area">
      <el-pagination
        v-show="authorities.includes('BACK:FRONT_T_USER_PAGE_LIST')"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchParams.pageNum"
        :page-size="searchParams.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 新增编辑弹框 -->
    <el-dialog
      :title="dialogAddUserTitle"
      :visible.sync="dialogAddUser"
      width="500px"
    >
      <div style="padding-right: 20px">
        <el-form
          ref="saveUserData"
          size="small"
          :model="saveUserData"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="昵称" prop="nickname">
            <el-input
              v-model="saveUserData.nickname"
              placeholder="请输入昵称"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="saveUserData.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAddUser = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmAddUser"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 会议次数详情弹框 -->
    <el-dialog
      title="会议详情"
      :visible.sync="dialogMeetingDetailData.show"
      width="800px"
    >
      <div class="dialog-meeting-top">
        <span
          >会议总时长：{{ dialogMeetingDetailData.meetingHoursNum }}小时</span
        >
        <span class="mL20"
          >回放总个数：{{ dialogMeetingDetailData.playbackNum }}个</span
        >
        <span class="mL20"
          >消耗金币总数：{{ dialogMeetingDetailData.consumeCoinAllNum }}个</span
        >
      </div>
      <div class="dialog-meeting-num-content">
        <el-table
          height="100%"
          :data="dialogMeetingDetailData.list"
          v-loading="loadingDetail"
          row-key="id"
          style="width: 100%"
          :header-cell-style="{ background: '#eef1f6' }"
        >
          <el-table-column
            prop="startTime"
            align="center"
            label="时间"
            width="180px"
          >
          </el-table-column>
          <el-table-column prop="meetingNameTitle" align="center" label="标题">
          </el-table-column>
          <el-table-column
            prop="consumeCoinNum"
            align="center"
            label="消耗金币"
          >
          </el-table-column>
          <el-table-column align="center" label="会议时长">
            <template slot-scope="scope">
              <span>{{ scope.row.meetingHours }}小时</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="回放">
            <template slot-scope="scope">
              <span v-if="!scope.row.openPlayBack">未开启</span>
              <el-link
                v-else
                type="primary"
                :underline="false"
                @click="handleSeeMeetingBack(scope.row)"
                >回放</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom-area">
        <el-pagination
          background
          @size-change="handleMeetingNumSizeChange"
          @current-change="handleMeetingNumCurrentChange"
          :current-page="dialogMeetingDetailParams.pageNum"
          :page-size="dialogMeetingDetailParams.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dialogMeetingDetailData.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 回放视频弹框 -->
    <el-dialog
      title="会议回放"
      :visible.sync="dialogMeetingBackData.show"
      width="700px"
    >
      <div class="dialog-meeting-back-content">
        <video
          v-if="dialogMeetingBackData.show"
          class="back-video"
          :src="dialogMeetingBackData.curUrl"
          controls
          :autoplay="dialogMeetingBackData.autoplay"
        ></video>
      </div>
      <div class="video-list">
        <div class="video-item" :class="[index == dialogMeetingBackData.curIndex ? 'video-item-active' : '']" v-for="(item, index) in dialogMeetingBackData.list" :key="index" @click="handleVideoItemClick(index)">
          视频{{ index + 1 }}
        </div>
      </div>
    </el-dialog>
    <!-- 去充值弹框 -->
    <el-dialog
      title="充值"
      :visible.sync="dialogRechargData.show"
      width="400px"
    >
      <div class="dialog-recharge-content">
        <span>充值金额：</span>
        <el-select
          class="rechage-select"
          size="small"
          v-model="dialogRechargData.selectId"
          placeholder="请选择充值金额"
        >
          <el-option
            v-for="item in dialogRechargData.selectList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogRechargData.show = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleConfirmRecharge"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 去扣除弹框 -->
    <el-dialog title="扣除" :visible.sync="dialogDeductData.show" width="400px">
      <div class="dialog-deduct-content">
        <div class="row-item">
          <span class="row-lable">扣除金币：</span>
          <el-input
            size="small"
            v-model="dialogDeductData.coinNum"
            placeholder="请输入扣除金币"
          ></el-input>
        </div>
        <div class="row-item">
          <span class="row-lable">备注：</span>
          <el-input
            size="small"
            type="textarea"
            rows="2"
            resize="none"
            v-model="dialogDeductData.remarks"
            placeholder="请输入备注"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogDeductData.show = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleConfirmDeduct"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  deductMoney,
  fastSaveClientUser,
  fastUpdateClientUser,
  getFrontUserList,
  getMeetingRoomNumPage,
  rechargeMoney,
  getRecordUrl,
} from "@/api/frontUser";
import { sysUserPasswordInit, sysUserDel, sysUserEnable } from "@/api/sysUser";
import { mapState } from "vuex";
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      selectDate: [],
      selectUserIds: [],
      userList: [],
      searchParams: {
        endTime: "",
        startTime: "",
        pageNum: 1,
        pageSize: 10,
        searchContent: "",
      },
      dialogAddUserTitle: "",
      dialogAddUser: false,
      editUserId: 0,
      total: 0,
      loading: false,
      loadingDetail: false,
      saveUserData: {
        id: null,
        nickname: "",
        mobile: "",
      },
      roleList: [],
      rules: {
        nickname: [
          { required: true, message: "昵称不能为空", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "change" },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!/^1\d{10}$/.test(value)) {
                return callback(new Error("手机号码不合要求！"));
              }
              return callback();
            },
          },
        ],
      },
      /* 会议详情数据 */
      dialogMeetingDetailParams: {
        pageNum: 1,
        pageSize: 10,
        userId: null,
      },
      dialogMeetingDetailData: {
        list: [],
        consumeCoinAllNum: 0, // 金币总消耗数
        meetingHoursNum: 0, // 会议总时长
        playbackNum: 0, // 总回放数
        total: 0,
        show: false,
      },
      /* 回放数据 */
      dialogMeetingBackData: {
        curIndex: 0,
        show: false,
        curUrl: "http://localhost:1234/public/video/test.mp4",
        urlList: [],
        autoplay: true,
      },
      /* 充值数据 */
      dialogRechargData: {
        show: false,
        selectId: null,
        selectList: [
          {
            goodsNum: 100,
            rmbNum: 100,
            id: 1,
            value: "100元100金币",
          },
          {
            goodsNum: 200,
            rmbNum: 190,
            id: 2,
            value: "200元190金币",
          },
          {
            goodsNum: 500,
            rmbNum: 470,
            id: 3,
            value: "470元500金币",
          },
          {
            goodsNum: 1000,
            rmbNum: 950,
            id: 4,
            value: "950元1000金币",
          },
        ],
        userId: "",
      },
      /* 扣除数据 */
      dialogDeductData: {
        show: false,
        userId: "",
        coinNum: "",
        remarks: "",
      },
    };
  },
  components: {},
  computed: {
    ...mapState(["authorities"]),
  },
  created() {
    this.getRegUserList();
  },
  mounted() {},
  methods: {
    /* 查看充值记录 */
    handleSeeRechargeRecord(row) {
      if (
        !this.authorities.includes(
          "BACK:BACK_T_ORDER_MONEY_RECHARGE_RECEIVE_COUNT_PAGE_LIST"
        )
      ) {
        return;
      }
      this.$router.push({
        path: "/userManage/registerList/rechargeRecord",
        query: {
          userId: row.id,
        },
      });
    },
    /* 处理回放item点击 */
    handleVideoItemClick(index) {
      this.dialogMeetingBackData.curIndex = index
      this.dialogMeetingBackData.curUrl = this.dialogMeetingBackData.list[index]
    },
    /* 点击查看回放 */
    async handleSeeMeetingBack(row) {
      if (row.openPlayBack == 0) {
        return;
      }
      if (this._getRecordUrlFlag) {
        return;
      }
      this._getRecordUrlFlag = true;
      try {
        this.msgWarning('回放获取中，请稍等！')
        const data = await getRecordUrl(row.id);
        this._getRecordUrlFlag = false;
        if (!data) {
          return this.msgWarning("暂未生成回放！");
        }
        if (!data.panoVideoUrlMerge && !data.panoVideoUrl) {
          return this.msgWarning("暂未生成回放！");
        }
        let list = data.panoVideoUrlMerge
          ? data.panoVideoUrlMerge.split(",")
          : data.panoVideoUrl.split(",");
        this.dialogMeetingBackData = {
          show: true,
          list,
          curIndex: 0,
          curUrl: list[0],
          autoplay: true,
        };
      } catch (err) {
        this._getRecordUrlFlag = false;
        let msg = err.msg ? err.msg : "获取回放视频发生异常！";
        console.error("getRecordUrl: ", err);
        this.msgError(msg);
      }
    },
    /* 点击会议次数 */
    handleSeeMeetingDetail(row) {
      if (!this.authorities.includes("BACK:MEETING_ROOM_NUM_PAGE")) {
        return;
      }
      this.dialogMeetingDetailData.show = true;
      this.dialogMeetingDetailParams.pageNum = 1;
      this.dialogMeetingDetailParams.userId = row.id;
      this.getMeetingNumList();
    },
    /* 单页数量改变 */
    handleMeetingNumSizeChange(pageSize) {
      this.dialogMeetingDetailParams.pageSize = pageSize;
      this.getMeetingNumList();
    },
    /* 页码改变 */
    handleMeetingNumCurrentChange(pageNum) {
      this.dialogMeetingDetailParams.pageNum = pageNum;
      this.getMeetingNumList();
    },
    /* 获取会议次数列表 */
    async getMeetingNumList() {
      try {
        this.loadingDetail = true;
        const {
          consumeCoinAllNum,
          meetingHoursNum,
          playbackNum,
          pageList: { records, total, pages, current },
        } = await getMeetingRoomNumPage(this.dialogMeetingDetailParams);
        this.loadingDetail = false;
        this.dialogMeetingDetailData = {
          consumeCoinAllNum,
          meetingHoursNum,
          playbackNum,
          list: records,
          total,
          show: true,
        };
        if (current > pages && pages) {
          this.dialogMeetingDetailParams.pageNum = pages;
          this.getMeetingNumList();
        }
      } catch (err) {
        this.loadingDetail = false;
        let msg = err.msg ? err.msg : "获取会议次数列表发生异常！";
        console.error("getMeetingRoomNumPage: ", err);
        this.msgError(msg);
      }
    },
    /* 去充值 */
    handleRecharge(row) {
      this.dialogRechargData.userId = row.id;
      this.dialogRechargData.show = true;
      this.dialogRechargData.selectId = null;
    },
    /* 确定充值 */
    async handleConfirmRecharge() {
      if (!this.dialogRechargData.selectId) {
        return this.msgWarning("请先选择充值金额！");
      }
      if (this._rechargeFlag) {
        return;
      }
      this._rechargeFlag = true;
      const { userId, selectId } = this.dialogRechargData;
      let goodsNum, rmbNum;
      this.dialogRechargData.selectList.forEach((item) => {
        if (selectId == item.id) {
          goodsNum = item.goodsNum;
          rmbNum = item.rmbNum;
        }
      });
      const saveData = {
        goodsNum,
        rmbNum,
        userId,
      };
      try {
        const data = await rechargeMoney(saveData);
        if (!data) {
          return this.msgError("充值发生异常！");
        }
        this.dialogRechargData.show = false;
        this._rechargeFlag = false;
        this.getRegUserList();
        this.msgSuccess("充值成功！");
      } catch (err) {
        this._rechargeFlag = false;
        let msg = err.msg ? err.msg : "充值发生异常！";
        console.error("rechargeMoney: ", err);
        this.msgError(msg);
      }
    },
    /* 去扣除 */
    handleDeduct(row) {
      this.dialogDeductData = {
        userId: row.id,
        show: true,
        remarks: "",
        coinNum: "",
      };
    },
    /* 确定扣除 */
    async handleConfirmDeduct() {
      const { userId, coinNum, remarks } = this.dialogDeductData;
      if (!coinNum) {
        return this.msgWarning("扣除金币数量不能为空！");
      }
      if (this._deductFlag) {
        return;
      }
      this._deductFlag = true;
      const saveData = {
        userId,
        coinNum,
        remarks,
      };
      try {
        const data = await deductMoney(saveData);
        if (!data) {
          return this.msgWarning("扣除发生异常！");
        }
        this._deductFlag = false;
        this.dialogDeductData.show = false;
        this.getRegUserList();
        this.msgSuccess("扣除成功！");
      } catch (err) {
        this._deductFlag = false;
        let msg = err.msg ? err.msg : "扣除发生异常！";
        console.error("rechargeMoney: ", err);
        this.msgError(msg);
      }
    },
    /* 查询日期改变 */
    handleDateParamsChange() {
      if (this.selectDate) {
        this.searchParams.startTime = this.selectDate[0];
        this.searchParams.endTime = this.selectDate[1];
      } else {
        this.searchParams.startTime = "";
        this.searchParams.endTime = "";
      }
    },
    /* 列表选择 */
    handleSelectionChange(list) {
      console.log(list);
      this.selectUserIds = list.map((item) => {
        return item.id;
      });
    },
    /* 修改用户信息 */
    async handleEditUserInfo(type, row) {
      let userId;
      if (type == "batch") {
        userId = this.selectUserIds[0];
        this.userList.forEach((item) => {
          if (item.id == userId) {
            row = item;
          }
        });
      } else if (type == "row") {
        userId = row.id;
      }
      const { nickname, mobile, regSource } = row;
      if (regSource == 2) {
        return this.msgWarning("只能修改内部添加的用户！");
      }
      this.saveUserData = {
        nickname,
        mobile,
        userId,
      };
      this.$nextTick(() => {
        if (this.$refs["saveUserData"]) {
          this.$refs["saveUserData"].clearValidate();
        }
      });
      this.dialogAddUserTitle = "编辑用户";
      this.dialogAddUser = true;
    },
    /* 删除用户 */
    handleDeleteUser(type, row) {
      let userIds = [];
      if (type == "batch") {
        userIds = this.selectUserIds;
      } else if (type == "row") {
        userIds = [row.id];
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await sysUserDel({
            userIds,
          });
          this.msgSuccess("删除成功！");
          this.getRegUserList();
        } catch (err) {
          let msg = err.msg ? err.msg : "删除用户发生异常！";
          console.error("sysUserDel: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 重置用户密码 */
    handleResetPassword(type, row) {
      let userIds = [];
      if (type == "batch") {
        userIds = this.selectUserIds;
      } else if (type == "row") {
        userIds = [row.id];
      }
      this.$confirm("确定初始化该用户密码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await sysUserPasswordInit({
            userIds,
          });
          this.msgSuccess("密码重置成功！");
        } catch (err) {
          let msg = err.msg ? err.msg : "重置密码发生异常！";
          console.error("sysUserPasswordInit: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 启用或者禁用用户 */
    async handleEnbleOrDisableUser(type, enable, row) {
      let userIds = [];
      if (type == "batch") {
        userIds = this.selectUserIds;
      } else if (type == "row") {
        userIds = [row.id];
      }
      this.$confirm(`确定要${enable == 1 ? "启用" : "禁用"}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await sysUserEnable({
            enable,
            userIds,
          });
          this.getRegUserList();
          this.msgSuccess("操作成功！");
        } catch (err) {
          `${enable == 1 ? "启用" : "禁用"}发生异常！`;
          console.error("sysUserEnable: ", err);
          this.msgError(msg);
        }
      });
    },
    /* 处理搜索 */
    handleSearch() {
      this.searchParams.pageNum = 1;
      this.getRegUserList();
    },
    /* 新增用户按钮点击 */
    handleCreateUser() {
      this.dialogAddUserTitle = "新增用户";
      this.dialogAddUser = true;
      this.$nextTick(() => {
        if (this.$refs["saveUserData"]) {
          this.$refs["saveUserData"].resetFields();
        }
      });
    },
    /* 页面变化 */
    handleCurrentChange(pageNum) {
      this.searchParams.pageNum = pageNum;
      this.getRegUserList();
    },
    /* 页面size变化 */
    handleSizeChange(pageSize) {
      this.searchParams.pageSize = pageSize;
      this.getRegUserList();
    },
    /* 确定添加用户 */
    handleConfirmAddUser() {
      this.$refs["saveUserData"].validate(async (valid) => {
        if (valid) {
          let optName = "";
          if (this._addOrEditFlag) {
            return;
          }
          this._addOrEditFlag = true;
          try {
            if (this.dialogAddUserTitle == "新增用户") {
              optName = "新增";
              await fastSaveClientUser(this.saveUserData);
            } else if (this.dialogAddUserTitle == "编辑用户") {
              optName = "编辑";
              await fastUpdateClientUser(this.saveUserData);
            }
            this._addOrEditFlag = false;
            this.getRegUserList();
            this.dialogAddUser = false;
            this.msgSuccess(`${optName}成功`);
          } catch (err) {
            this._addOrEditFlag = false;
            let msg = err.msg ? err.msg : `${optName}用户发生异常！`;
            console.error("handleConfirmAddUser err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 获取用户列表 */
    async getRegUserList() {
      if (!this.authorities.includes("BACK:FRONT_T_USER_PAGE_LIST")) {
        return;
      }
      try {
        this.loading = true;
        const {
          pageList: { total, records, pages, current },
        } = await getFrontUserList(this.searchParams);
        this.loading = false;
        this.total = total;
        this.userList = records;
        if (current > pages && pages) {
          this.searchParams.pageNum = pages;
          this.getRegUserList();
        }
      } catch (err) {
        this.loading = false;
        let msg = err.msg ? err.msg : "获取用户列表发生异常";
        console.error("getFrontUserList err: ", err);
        this.msgError(msg);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.reg-user-manage {
  height: 100%;
  padding: 20px 20px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .search-area {
    font-size: 12px;
    padding: 0 10px 0;
    text-align: left;
  }
  .opt-area {
    padding: 0 10px 10px;
    text-align: right;
  }
  .list-area {
    flex: 1;
    overflow-x: auto;
    .m-l-10 {
      margin-left: 10px;
    }
  }
  .bottom-area {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-select {
    height: 84px;
    width: 84px;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .add-head {
      width: 84px;
      height: 84px;
    }
    .add-icon {
      font-size: 30px;
    }
  }
  .dialog-meeting-top {
    padding-bottom: 20px;
  }
  .dialog-meeting-num-content {
    height: 500px;
    overflow-y: auto;
  }
  .dialog-meeting-back-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .back-video {
      height: calc(660px / 1280 * 720);
      width: 660px;
      object-fit: cover;
    }
  }
  .video-list{
    margin-top: 20px;
    border: 1px solid #f1f1f1;
    padding: 10px;
    overflow: hidden;
    .video-item {
      float: left;
      margin-right: 30px;
      line-height: 26px;
      cursor: pointer;
      &-active{
        color: #409eff;
      }
      &:hover{
        color:#409eff;
      }
    }
  }
  .dialog-recharge-content {
    display: flex;
    align-items: center;
    .rechage-select {
      flex: 1;
    }
  }
  .dialog-deduct-content {
    .row-item {
      display: flex;
      align-items: center;
      &:nth-of-type(2) {
        margin-top: 20px;
      }
      .row-lable {
        width: 100px;
      }
    }
  }
}
</style>